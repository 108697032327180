.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.p10 {
  padding: 10px;
}

.p20 {
  padding: 20px;
}

.p30 {
  padding: 30px;
}

.p40 {
  padding: 40px;
}

.p50 {
  padding: 50px;
}

.card-shadow {
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(20, 19, 34, 0.03), 0 0 10px 0 rgba(20, 19, 34, 0.02);
}
