.ant-layout.ant-layout-has-sider {
  min-height: 100%;
}

.side-layout {
  overflow: hidden;
  height: 100vh;
  position: sticky;
  right: 0;
  left: 0;
  top: 0;
}

.side-layout-top {
  height: calc(100% - 40px);
}

.side-layout-identity__logo {
  width: 12rem;
  height: 2rem;
  margin: 1rem;
  margin-left: 10px;
  background: url(../../../assets/img/booston-logo.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

.side-layout-identity--collapsed .side-layout-identity__logo {
  width: 32px;
  height: 2rem;
  margin: 1rem 24px;
  background: url(../../../assets/img/booston-icon.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

.side-layout-footer {
  background-color: #172d44;
  height: 40px;
}
